<template>
  <div class="content_box">
    <Banner
      class="wow fadeIn Banner "
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
    ></Banner>
    <div class="views-home" id="views-home">
      <Product class="container wow fadeInUp"></Product>
      <hd-solution
        class="solution container wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      ></hd-solution>
      <hd-feature
        class="container wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      ></hd-feature>
      <hd-customer
        class="container wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      ></hd-customer>
      <hd-concat
        class="container wow fadeInUp"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      ></hd-concat>
      <News
        class="news container wow fadeInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.2s"
      ></News>
    </div>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>

<script>
import WOW from "wowjs";
import Banner from "@/components/home/Banner.vue";
import Product from "@/components/home/Product.vue";
import News from "@/components/home/News.vue";
import HdCustomer from "@/components/home/HdCustomer.vue";
import HdSolution from "@/components/home/HdSolution.vue";
import HdConcat from "@/components/home/HdConcat.vue";
import HdFeature from "@/components/home/HdFeature.vue";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "Home",
  components: {
    Banner,
    Product,
    News,
    HdSolution,
    HdCustomer,
    HdConcat,
    HdFeature,
    pageMore,
  },
  data() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/icon-foot.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
// .views-home {
//   width: 91.66%;
//   margin: auto;
//   .container {
//     width: 91.66%;
//     padding-top: 100px;
//     margin: 0 auto;
//   }
// }
.Banner {
  width: 100%;
  //91.66
  margin: 0 auto;
}
.views-home {
  width: 83%;
  max-width: 1760px;
  padding-right: 0;
  padding-left: 0;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  .container {
    width: 100%;
    // width: 1760px;
    padding-top: 85px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1560px) {
  .views-home {
    .container {
      padding-top: 80px;
      &.solution {
        padding-bottom: 80px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .views-home {
    .container {
      // width: 83%;
      padding-top: 55px;
      // margin:0 auto;
      &.solution {
        padding-bottom: 60px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .views-home {
    // width:83%;
    // width: 100% !important;
    .container {
      padding-top: 40px;
      &.solution {
        padding-bottom: 45px;
      }
    }
  }
}
</style>

<template>
  <div class="components-home-news">
    <div class="head-title  animate__animated animate__fadeInUp">
      <h2>新闻中心</h2>
      <h3>有声视界，悦听乐看</h3>
    </div>
    <div class="news-swiper swiper-container">
      <ul class="swiper-wrapper">
        <li
          v-for="item in list"
          :key="item.name"
          :class="['news', item.name, 'swiper-slide', 'swiper-no-swiping']"
        >
          <news-item :item="item"></news-item>
        </li>
      </ul>
    </div>
    <el-button-group class="btns">
      <el-button
        round
        icon="el-icon-arrow-left"
        :class="['prev-btn', { active: !isActive }]"
      ></el-button>
      <el-button
        round
        icon="el-icon-arrow-right"
        :class="['next-btn', { active: isActive }]"
        @mouseout.native="onMouseoutBtns"
        @mouseover.native="onMouseoverBtns"
      ></el-button>
    </el-button-group>
  </div>
</template>
<script>
import NewsItem from "@/components/home/NewsItem.vue";
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
  Lazy,
  History,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
  Lazy,
  History,
]);
export default {
  name: "components-home-news",
  components: { NewsItem },
  data() {
    return {
      isActive: false,
      list: [
        {
          name: "huabei",
          date: "2020.12.09",
          title: "疫情降薪返还潮已至，你有“薪”动吗？",
          pic: require("@/assets/images/idxnew-img1.jpg"),
          url: "",
          des:
            "回望2020，一路的成长始终伴随着伤痛与坚忍。新年伊始当举国人民还在欢天喜地迎接即…",
        },
        {
          name: "huashu",
          date: "2020.10.01",
          title: "新征程 新起点 | 航动科技2大中心+1个…",
          url: "",
          pic: require("@/assets/images/idxnew-img2.jpg"),
          des:
            "2020年9月30日，乘着国庆中秋双节前夕的喜庆气氛，上海航动科技有限公司…",
        },
      ],
    };
  },
  mounted() {
    new Swiper(".news-swiper", {
      autoplay: true,
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: false,
      loop: true,
      speed: 800,
      noSwiping: false,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn",
      },
      breakpoints: {
        680: {
          slidesPerView: 3,
          spaceBetween: 0,
          noSwiping: true,
          loop: false,
        },
      },
    });
  },
  methods: {
    onMouseoverBtns() {
      this.isActive = true;
    },
    onMouseoutBtns() {
      this.isActive = false;
    },
  },
};
</script>
<style lang="less" scoped>
.components-home-news {
  position: relative;
  padding: 95px 0 100px;
  .news-swiper {
    display: flex;
    align-items: center;
    .news {
      padding: 35px 20px 0;
    }
  }
  .btns {
    display: none;
    .el-button {
      width: 50px;
      height: 50px;
      line-height: 50px;
      padding: 0;
      transition: 0.1s;

      border: solid 1px #999999;
      border-radius: 100%;
      font-size: 18px;
      color: #9e9e9f;
      text-align: center;
      transition: all 0.5s ease;
      &:first-child {
        margin-right: 20px;
      }
      &.active {
        background-color: #1f63d2;
        color: #fff;
        border-color: #1f63d2;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .components-home-news {
    padding: 75px 0 80px;
  }
}
@media screen and (max-width: 1200px) {
  .components-home-news {
    .news-swiper {
      .news {
        padding: 25px 15px 0px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-news {
    .news-swiper {
      .news {
        padding-left: 45px;
        padding-right: 45px;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-button {
        width: 26px;
        height: 26px;
        line-height: 24px;
        &.prev-btn {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 3;
        }
        &.next-btn {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 3;
        }
      }
    }
  }
}
</style>

<template>
  <div class="components-home-product">
    <div class="head-title  animate__animated animate__fadeInUp">
      <h2>核心产品</h2>
      <h3>多模态情感识别技术，全流程数据可视化平台</h3>
    </div>
    <el-row class="productList">
      <el-col
        :span="6"
        v-for="(item,index) in productList"
        :key="item.name"
        :class="[{active:index==active},item.name]"
        v-on:mouseover.native="onMouseover(index)"
      >
        <img
          :src="item.pic"
          alt=""
        >
        <i></i>
        <h3>{{item.title}}</h3>
        <h4>{{item.des}}</h4>
      </el-col>
    </el-row>
    <ul class="box-wrapper">
      <li
        v-for="(item,index) in productList"
        :key="item.name"
        :class="['box-item',{active:index==active}]"
      >
        <div>
          <h3>{{item.title}}</h3>
          <h4>{{item.des}}</h4>
          <div class="intro">{{item.intro}}</div>
          <span class="more small">了解更多<i class="el-icon-arrow-right"></i></span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "components-home-banner",
  data() {
    return {
      active: 0,
      productList: [
        {
          name: "huajia",
          title: "话加",
          des: "智能语音系统",
          pic: require("@/assets/images/pro-ico1.png"),
          intro:
            "依托人工智能和互联网技术，为企业提供全业务流程智能语音交互服务和数据可视化监控，赋能企业产能落地",
        },
        {
          name: "huabei",
          title: "话倍",
          pic: require("@/assets/images/pro-ico2.png"),
          des: "智能坐席助手",
          intro:
            "借助航动自研的人机协同系统搭载四分屏技术，专注提高客户服务效率，成为企业的智能增长引擎",
        },
        {
          name: "huashu",
          pic: require("@/assets/images/pro-ico3.png"),
          title: "话数",
          des: "智能权益系统",
          intro:
            "基于AI算法和大数据技术，通过精准用户画像和智能权益匹配提升愉悦互动消费体验，帮助企业实现全触点数字化营销，提高客户服务满意度",
        },
        {
          pic: require("@/assets/images/pro-ico4.png"),
          name: "huayun",
          title: "话云",
          des: "云呼叫中心",
          intro:
            "支持一键灵活部署，提供丰富接口快速对接企业原有系统，一站式优化客户服务流程及管理",
        },
      ],
    };
  },
  methods: {
    onMouseover(index) {
      this.active = index;

    },
  },
};
</script>
<style lang="less" scoped>
.components-home-product {

  .productList {
    display: flex;
    align-items: center;
    margin-top: 55px;
    .el-col {
      border-radius: 5px;
      text-align: center;
      // height: 337px;
        height: 17.448vw  /* 335/19.2 */;

      text-align: center;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: all 0.5s ease;
      transform: translate3d(0, 0, 0);
      position: relative;
      img {
        transition: all 0.5s ease;
      }
      &:after,
      &:before {
        content: "";
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 0;
        position: absolute;
        transition: all 0.5s ease;
        opacity: 0;
      }
      &:before {
        background: url(../../assets/images/pro-bgimg3.png) no-repeat center
          top/100%;
        transform: translate(10px, 10px);
      }
      &:after {
        background: url(../../assets/images/pro-bgimg2.png) no-repeat center
          top/100%;
        transform: translate(-10px, 10px);
      }
      i {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 0;
        position: absolute;
        transition: all 0.5s ease;
        opacity: 0;
        background: url(../../assets/images/pro-bgimg1.png) no-repeat center
          top/100%;
        transform: translateY(-10px);
      }
      h3 {
        font-size: 24px;
        color: #333333;
        transition: all 0.5s ease;
      }
      h4 {
        transition: all 0.5s ease;
        font-size: 16px;
        color: #ffffff;
        visibility: hidden;
        opacity: 0;
      }
      &.active {
        background-color: #2063d2;
        color: #fff;
        &:before,
        &:after {
          transform: translate(0, 0);
          opacity: 1;
        }
        img {
          transform: translateY(-10px);
        }
        i {
          transform: translateY(0);
          opacity: 1;
        }
        h3 {
          visibility: hidden;
          opacity: 0;
        }
        h4 {
          visibility: visible;
          opacity: 1;
          transform: translateY(-15px);
        }
      }
    }
  }
  .box-wrapper {
    .box-item {
      height: auto;
      position: relative;
      & > div {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
        width: 100%;
      }
      &.active {
        & > div {
          transition: all 0.8s ease;
          visibility: visible;
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
    .more {
      &.small {
        background-color: rgba(0, 0, 0, 0);
        color: #333;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .components-home-product {
    .productList {
      .el-col {
        height: 270px;
        img {
          width: 130px;
        }
        h3 {
          font-size: 20px;
        }
        &.active {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .components-home-product {
    .productList {
      .el-col {
        height: 240px;
        img {
          width: 130px;
        }
        h3 {
          font-size: 20px;
        }
        &.active {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) and (orientation: portrait) {
  .components-home-product {
    .productList {
      .el-col {
        height: 180px;
        img {
          width: 90px;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-product {
    .productList {
      .el-col {
        height: 90px;
        padding-top: 5px;
        img {
          width: 50px;
        }
        h3 {
          font-size: 14px;
        }
        h4 {
          display: none;
        }
        &.active {
          h3 {
            visibility: visible;
            opacity: 1;
            color: #fff;
          }
        }
      }
    }
    .box-wrapper {
      // margin-top: 20px;
      h3 {
        font-size: 16px;
      }
      h4 {
        margin-bottom: 15px;
        font-size: 12px;
      }
      .intro {
        margin-bottom: -15px;
        max-width: 100%;
        font-size: 12px;
        padding: 0 15px;
      }
    }
  }
}
</style>

<template>
  <div class="components-home-banner">
    <div class="swiper-container banner-swiper">
      <ul class="swiper-wrapper">
        <li class="swiper-slide" v-for="(item, index) in list" :key="index">
          <img class="pc" :src="item.pc_pic" alt="" @click="goOut(index)" />
          <img class="banner_bg pc" v-show="item.pc_bg" :src="item.pc_bg" alt="" />
          <img :src="item.mobile_pic" class="mobile" alt="" @click="goOut(index)" />
          <img class="banner_bg mobile" v-show="item.mobile_bg" :src="item.mobile_bg" alt="" />
        </li>
      </ul>
      <!-- 如果需要分页器 -->
      <ul class="banpag paginationBtn">
        <li v-for="item in 4" :key="item"></li>
      </ul>
    </div>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
  Thumbs,
} from "swiper";
import router from '@/router/index';
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
  Thumbs,
]);
export default {
  name: "components-home-banner",
  data () {
    return {
      list: [
        {
          // pc_pic: require("@/assets/images/banner6.png"),
          pc_pic: require("@/assets/images/banner6xx.png"),
          mobile_pic: require("@/assets/images/mbl-banner6xx.png"),
        },
        {
          pc_pic: require("@/assets/images/banner.jpg"),
          pc_bg: require("@/assets/images/banner1.png"),
          mobile_pic: require("@/assets/images/mbl-banner1.jpg"),
          mobile_bg: require("@/assets/images/mbl-banner1.jpg"),
        },
        {
          pc_pic: require("@/assets/images/banner2.jpg"),
          mobile_pic: require("@/assets/images/mbl-banner2.jpg"),
        },
        {
          pc_pic: require("@/assets/images/banner5.png"),
          pc_bg: require("@/assets/images/banner3-1.png"),
          mobile_pic: require("@/assets/images/mbl-banner3.jpg"),
          mobile_bg: require("@/assets/images/mbl-banner1.jpg"),
        },
      ],
    };
  },
  mounted () {
    // eslint-disable-next-line no-unused-vars
    new Swiper(".banner-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: false,
      loop: true,
      loopedSlides: 5,
      speed: 1000,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".paginationBtn",
        clickable: true,
        renderBullet: function (index, className) {
          return '<li class="' + className + '"><i></i></span>';
        },
      },
      observer: true,
      observeParents: true,
    });
  },
  methods: {
    goOut (index) {
      if (index === 0) {
        const routeUrl = router.resolve({
          name: 'shanhai',
        })
        console.log(routeUrl);
        window.open(routeUrl.href, '_blank')
      }
    },
  },
};
</script>
<style lang="less" scoped>
.components-home-banner {
  .swiper-wrapper {
    li {
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
      }
    }

    .mobile {
      display: none;
      visibility: hidden;
    }
  }

  .banner_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    animation: banimg 3s ease infinite;
  }

  /deep/ .banpag {
    position: absolute;
    bottom: 60px !important;
    left: 50% !important;
    transform: translateX(-50%);
    z-index: 3;
    display: flex;
    font-size: 0;
    width: auto !important;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #fff;
      opacity: 0.6;
      margin: 0 20px !important;
      position: relative;
      cursor: pointer;

      i {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: solid 1px #fff;
        animation: banpag 3s ease 0.8s infinite;
        border-radius: 100%;
        display: none;
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: solid 1px #fff;
        border-radius: 100%;
        display: none;
        box-sizing: border-box;
      }

      &:before {
        animation: banpag 3s ease 3s infinite;
      }

      &:after {
        animation: banpag 3s ease 0.4s infinite;
      }

      &.swiper-pagination-bullet-active {
        opacity: 1;

        &:after,
        &:before,
        i {
          display: block;
        }
      }
    }
  }
}

@keyframes banpag {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes banimg {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  60% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@media screen and (max-width: 1560px) {
  .components-home-banner {
    /deep/ .banpag {
      bottom: 25px !important;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: portrait) {
  .components-home-banner {
    /deep/ .banpag {
      li {
        margin: 0 10px !important;
      }
    }
  }
}

/* 手机端 - portrait指定输出设备中的页面可见区域高度大于或等于宽度*/
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-banner {
    .swiper-wrapper {
      .pc {
        display: none;
        visibility: hidden;
      }

      .mobile {
        display: inline-block;
        visibility: visible;
      }
    }

    /deep/ .banpag {
      bottom: 15px !important;

      li {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin: 0 8px !important;
      }
    }
  }
}
</style>

<template>
  <div class="components-home-newsItem">
    <div class="post animate__animated animate__fadeInUp">
       <img
        :src="item.pic"
        :alt="item.title"
      >
    </div>
    <ul class="detail">
      <li class="date"><i class="el-icon-time"></i>{{item.date}}</li>
      <li class="title">{{item.title}}</li>
      <li class="des">{{item.des}}</li>
      <li class="more small">了解更多<i class="el-icon-arrow-right"></i></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "components-home-newsItem",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.components-home-newsItem {
  text-align: left;
  .post {
    background-color: #f2f8ff;
    border-radius: 3px;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 3px;
      transition: all 0.5s ease;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .detail {
    background: url(../../assets/images/idx-newbg.jpg) no-repeat center
      center/cover;
    padding: 25px 50px 35px;
    .date {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px;
      i {
        margin-right: 10px;
      }
    }
    .title {
      font-size: 22px;
      color: #333333;
      margin-bottom: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.5s ease 0s;
    }
    .des {
      font-size: 16px;
      line-height: 30px;
      color: #666;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .more {
      margin-top: 20px;
      background-color: rgba(0, 0, 0, 0);
      color: #333;
      i {
        display: inline-block;
        margin-left: 10px;
      }
      &:hover {
        background-color: #1c5cc2;
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .components-home-newsItem {
    .detail {
      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .des {
        font-size: 14px;
        line-height: 28px;
        height: 56px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .components-home-newsItem {
    .detail {
      padding: 20px 25px 25px;
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-newsItem {
    .detail {
      .date {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .des {
        line-height: 20px;
        height: 40px;
        font-size: 12px;
      }
      .more {
        margin-top: 15px;
      }
    }
  }
}
</style>
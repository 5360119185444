<template>
  <div class="components-home-hdCustomer">
    <!-- 客户案例 -->
    <div class="head-title">
      <h2>品质信赖</h2>
      <h3>数万企业用户的共同选择，多行业解决方案个性服务</h3>
    </div>
    <ul class="list">
      <li
        v-for="(item, index) in 10"
        :key="index"
        :class="{ active: index == active }"
        v-on:mouseover="onMouseover(index)"
        class="wow fadeInDown"
        data-wow-duration="0.8"
        :data-wow-delay="0.3 * index + 's'"
      >
        <el-image class="customer-logo" lazy :src="logoURL(index)">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-button-group class="logo-btns" v-if="index == 9">
          <el-button
            round
            icon="el-icon-arrow-left"
            :class="['prev-btn', { active: !isActive }]"
            @click="onPrevClick"
          ></el-button>
          <el-button
            round
            icon="el-icon-arrow-right"
            :class="['next-btn', { active: isActive }]"
            @mouseout.native="onMouseoutBtns"
            @mouseover.native="onMouseoverBtns"
            @click="onNextClick"
          ></el-button>
        </el-button-group>
      </li>
    </ul>
    <ul
      class="box-wrapper wow fadeInLeft"
      data-wow-duration="'0.8s'"
      data-wow-delay="0.5s"
    >
      <li
        v-for="(item, index) in list"
        :key="item.name"
        :class="['box-item', { 'active fadeIns': index == active }]"
      >
        <div>
          <h3>{{ item.title }}</h3>
          <h4>{{ item.des }}</h4>
          <span class="more small"
            >了解更多<i class="el-icon-arrow-right"></i
          ></span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "components-home-hdCustomer",
  data() {
    return {
      active: 0,
      isActive: false,
      list: [
        {
          title: "联通",
          des:
            "中国唯一一家在纽约、香港、上海三地同时上市的联通运营企业，连续多年入选“世界500强企业”，与航动科技合作长达五年，航动为其全国超20个省份提供呼叫中心业务支撑。据工信部公布的2020第三季度申诉举报数据来看，联通业务投诉率仅约为移动半数。",
        },
        {
          title: "电信",
          des:
            "中国特大型国有通信企业、上海世博会全球合作伙伴，连续多年入选“界500强企业”。航动科技为中国电信山东分公司提供全媒体呼叫中心服务，携手打造DICT合作生态圈。",
        },
        {
          title: "阿里云",
          des:
            "阿里巴巴集团旗下云计算品牌，全球卓越的云计算技术和服务提供商。航动科技曾为其打造的企业级协同办公平台-钉钉-提供底层技术支持。",
        },
        {
          title: "阳光保险",
          des:
            "成立于2005年，国内七大保险集团之一，中国500强企业。从2014年9月初次合作至今，与航动科技达成长期战略伙伴关系，也是航动“话倍”四分屏首个商用试验伙伴。",
        },
        {
          title: "泰康人寿",
          des:
            "系1996年8月22日经中国人民银行总行批准成立的全国性、股份制人寿保险公司，于2018、2019年跻身世界500强，位列2020北京民营企业百强榜前十。航动科技话加智能语音能力中台助力泰康降本增效，快速完成智慧服务平台搭建。",
        },
        {
          title: "南京银行",
          des:
            "1996年成立的股份制商业银行，于2007年在上海证券交易所上市成为全国第一家登陆上交所的城市商行。通过航动科技长期合作伙伴江苏联通与航动牵手，为其提供大数据精准营销服务技术支持。",
        },
        {
          title: "福佑卡车",
          des:
            "中国最大的整车运输科技运力平台，以大数据和AI重塑整车货运体验。2020年7月，获评艾媒金榜发布的《2020年中国新经济准独角兽垂直领域物流服务TOP5》榜单第2名。与航动科技联手，应用大数据和算法模型打造智能客服营销系统，通过对货主企业和运力画像，充分挖掘用户需求。",
        },
        {
          title: "人人车",
          des:
            "国内最大的C2C二手车交易平台，曾登上胡润研究院发布的《2019胡润全球独角兽榜》。通过航动科技智能语音能力平台，实现全年成交月度复合增长率超30%。",
        },
        // {
        //   title: "12315",
        //   des:
        //     "消费者申诉举报专线电话和全国互联网平台，通过与航动科技达成合作，大大提高12315处理投诉的效率与速度，保护消费者合法权益，营造健康市场生态环境。",
        // },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
    const timer = setInterval(() => {
      // 某些定时器操作
      this.active++;
      if (this.active >= this.list.length) {
        this.active = 0;
      }
    }, 6000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  methods: {
    logoURL(index) {
      return require(`@/assets/images/pinzhi-logo${index + 1}.jpg`);
    },
    onMouseover(index) {
      let len = this.list.length;
      if (index < len) {
        this.active = index;
      }
    },
    onMouseoverBtns() {
      this.isActive = true;
    },
    onMouseoutBtns() {
      this.isActive = false;
    },
    onPrevClick() {
      let len = this.list.length;
      let active = this.active;
      this.active = active >= 1 ? active - 1 : len - 1;
    },
    onNextClick() {
      let len = this.list.length;
      let active = this.active;
      this.active = this.active >= len - 1 ? 0 : active + 1;
    },
  },
};
</script>
<style lang="less" scoped>
.components-home-hdCustomer {
  .list {
    margin: 30px -10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > li {
      width: 20%;
      padding: 10px;
      position: relative;
      &.active {
        .customer-logo {
          border-color: #2056ad;
        }
      }
      .customer-logo {
        border: solid 1px #eeeeee;
        overflow: hidden;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
      }
    }
    .logo-btns {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      .el-button {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        border-radius: 50%;
        padding: 0;
        transition: 0.1s;
        &:first-child {
          margin-right: 20px;
        }
        &.active {
          background-color: #1f63d2;
          color: #fff;
          border-color: #1f63d2;
        }
      }
    }
  }
  .box-wrapper {
    padding: 50px 95px 75px 80px;
    text-align: left;

    .box-item {
      height: 100%;
      position: relative;
      display: none;
      visibility: hidden;

      h3 {
        color: #2056ad;
        margin-bottom: 30px;
      }
      h4 {
        margin-bottom: 45px;
      }
      & > div {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-45%);
      }
      &.active {
        display: flex;
        visibility: visible;
        & > div {
          transition: all 0.8s ease;
          visibility: visible;
          transform: translateY(-50%);
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .components-home-hdCustomer {
    .list {
      .logo-btns {
        .el-button {
          width: 40px;
          height: 40px;
          line-height: 38px;
          font-size: 16px;
        }
      }
    }
    .box-wrapper {
      padding: 50px 80px 60px 80px;
    }
  }
}
@media screen and (max-width: 992px) and (orientation: portrait) {
  .components-home-hdCustomer {
    .list {
      .logo-btns {
        .el-button {
          width: 30px;
          height: 30px;
          line-height: 28px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-hdCustomer {
    .list {
      & > li {
        width: 33.333%;
        padding: 5px;
        &:last-child {
          display: none;
        }
      }
      .logo-btns {
        .el-button {
          width: 40px;
          height: 40px;
          line-height: 38px;
          font-size: 16px;
        }
      }
    }
    .box-wrapper {
      height: 227px;
      padding: 25px 15px;
      .box-item {
        h4 {
          margin-bottom: -20px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .components-home-hdCustomer {
    .list {
      box-sizing: border-box;
      & > li {
        box-sizing: border-box;
        width: 50%;
        padding: 10px !important;
      }
    }
  }
}
.fadeIns {
  animation: fadeIns 1s ease-in;
}
@keyframes fadeIns {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<template>
  <div class="components-home-hdConcat">
    <!-- 共建生态 -->
    <div class="head-title animate__animated animate__fadeInUp">
      <h2>共建生态</h2>
      <h3>海纳专业人才共向未来，携手匠心企业智造美好</h3>
    </div>
    <div class="hdConcat-swiper pc swiper-container">
      <!-- 轮播内容 -->
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item,index) in list"
          :key="index"
        >
          <div class="concat-item">
            <img
              class="pic"
              :src="item.img"
              alt=""
            >
            <div class="concat-content">
              <h3>{{item.title}}</h3>
              <div class="detail">{{item.detail}}</div>
              <span
                class="blue-more"
                @click="onDemoClick"
              >免费体验</span>
            </div>
          </div>

        </div>
      </div>
      <!-- 上一步下一步 -->
      <div class="prev-btn"></div>
      <div class="next-btn"></div>
    </div>
    <div class="hdConcat-swiper mobile swiper-container">
      <!-- 轮播内容 -->
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item,index) in list"
          :key="index"
        >
          <div class="concat-item">
            <img
              class="pic"
              :src="item.img"
              alt=""
            >
            <div class="concat-content">
              <h3>{{item.title}}</h3>
              <div class="detail">{{item.detail}}</div>
              <span
                class="blue-more"
                @click="onDemoClick"
              >免费体验</span>
            </div>
          </div>

        </div>
      </div>
      <!-- 上一步下一步 -->
      <div class="prev-btn"></div>
      <div class="next-btn"></div>
    </div>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
]);
export default {
  name: "components-home-hdConcat",
  data() {
    return {
      active: 0,
      list: [
        {
          title: "牵手企业与科技",
          url: "",
          detail: "AI精准营销，赋能企业数智化升级",
          img: require("@/assets/images/stai-img1.png"),
        },
        {
          title: "连结用户与生活",
          url: "",
          detail: "智能匹配用户权益，美好生活尽在掌益",
          img: require("@/assets/images/stai-img2.png"),
        },
        {
          title: "连接人才与发展",
          url: "",
          detail: "育人才，共成长，向未来",
          img: require("@/assets/images/stai-img3.png"),
        },
      ],
    };
  },
  mounted() {
    new Swiper(".hdConcat-swiper.pc", {
      slidesPerView: 1.2,
      spaceBetween: 20,
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      loop: true,
      speed: 800,
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn",
      },
      autoplay:true,
      observer: true,
      observeParents: true,
      breakpoints: {
        1681: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 20,
            stretch: 529,
            depth: 700,
            modifier: 1,
            slideShadows: false,
          },
        },
        1601: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 20,
            stretch: 440,
            depth: 700,
            modifier: 1,
            slideShadows: false,
          },
        },
        1561: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 20,
            stretch: 400,
            depth: 700,
            modifier: 1,
            slideShadows: false,
          },
        },
        1366: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 20,
            stretch: 345,
            depth: 700,
            modifier: 1,
            slideShadows: false,
          },
        },
        1024: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 20,
            stretch: 240,
            depth: 800,
            modifier: 1,
            slideShadows: false,
          },
        },
        768: {
          slidesPerView: 1.4,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 35,
            stretch: 140,
            depth: 800,
            modifier: 1,
            slideShadows: false,
          },
        },
      },
    });
    new Swiper(".hdConcat-swiper.mobile", {
      slidesPerView: 1.3,
      spaceBetween: 20,
      grabCursor: true,
      centeredSlides: true,
      loop: true,
      speed: 800,
      autoplay:true,
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn",
      },
      observer: true,
      observeParents: true,
    });
  },
  methods: {
    onDemoClick() {
      this.$router.push({ name: "applyPage" });
    },
  },
};
</script>
<style lang="less" scoped>
.components-home-hdConcat {
  .hdConcat-swiper {
    margin-top: 35px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .prev-btn,
    .next-btn {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 400px;
      width: 14.5%;
      z-index: 2;
    }
    .prev-btn {
      left: 0;
    }
    .next-btn {
      right: 0;
    }
    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      .concat-item {
        .pic {
          opacity: 1;
        }
        .concat-content {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .concat-item {
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: -1;
      }
      .pic {
        opacity: 0.5;
        transition: all 0.5s ease;
      }
      .concat-content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
        h3 {
          font-size: 36px;
          color: #1f63d2;
          margin-bottom: 20px;
        }
        .detail {
          font-size: 18px;
          color: #333333;
          margin-bottom: 55px;
        }
        .more {
          background-color: #1f63d2;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-hdConcat {
    .hdConcat-swiper {
      .concat-item {
        .concat-content {
          position: relative;
          background: url(../../assets/images/pinzhi-bg.jpg) no-repeat center
            center/cover;
          box-shadow: inset 0px 0px 21px 0px rgba(28, 92, 194, 0.1);
          padding: 20px 15px;
          visibility: visible;
          opacity: 1;
          h3 {
            font-size: 18px;
          }
          .detail {
            margin-bottom: 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <!-- 行业解决方案 -->
  <div class="commponents-home-hdSolution">
    <div class="head-title  animate__animated animate__fadeInUp">
      <h2>解决方案</h2>
      <h3>深入行业场景建设，个性定制数字赋能</h3>
    </div>
    <!--大屏上显示的主轮播图-->
    <div class="full-swiper pc swiper-container">
      <ul class="swiper-wrapper" style="height: 6.25rem">
        <li class="swiper-slide" v-for="(item, index) in list" :key="index">
          <el-row class="content-wrapper">
            <el-row class="content">
              <h3 class="title">{{ item.title }}</h3>
              <div class="detail">{{ item.detail }}</div>
              <ul class="link-list">
                <li v-for="(link, linkIndex) in item.linkList" :key="linkIndex">
                  {{ link.title }}
                  <!-- <span class="g">|</span> -->
                </li>
              </ul>
              <span class="blue-more"
                >了解更多<i class="el-icon-arrow-right"></i
              ></span>
            </el-row>
            <img
              :src="item.img"
              v-if="index == 0 || index == list.length - 1"
            />
            <img v-else v-lazy="item.img" />
          </el-row>
        </li>
      </ul>
    </div>
    <!--小屏幕下显示的主轮播图-->
    <div class="full-mobile-swiper mobile swiper-container">
      <ul class="swiper-wrapper">
        <li class="swiper-slide" v-for="(item, index) in list" :key="index">
          <el-row class="content-wrapper">
            <img :src="item.img" />
            <el-row class="content">
              <h3 class="title">{{ item.title }}</h3>
              <div class="detail">{{ item.detail }}</div>
              <ul class="link-list">
                <li v-for="(link, linkIndex) in item.linkList" :key="linkIndex">
                  {{ link.title }}
                  <!-- <span class="g">|</span> -->
                </li>
              </ul>
              <span class="blue-more"
                >了解更多<i class="el-icon-arrow-right"></i
              ></span>
            </el-row>
          </el-row>
        </li>
      </ul>
    </div>
    <!--缩略图-->
    <el-row class="thumb-wrapper">
      <el-button-group class="opt-btns">
        <el-button
          :class="['prev-btn', { active: !active }]"
          round
          icon="el-icon-arrow-left"
        ></el-button>
        <el-button
          round
          :class="['next-btn', { active: active }]"
          icon="el-icon-arrow-right"
          @mouseout.native="onMouseout"
          @mouseover.native="onMouseover"
        ></el-button>
      </el-button-group>
      <el-button-group class="opt-btns opt-btns-mobile">
        <el-button
          :class="['prev-btn-mobile', { active: !active }]"
          round
          icon="el-icon-arrow-left"
        ></el-button>
        <el-button
          round
          :class="['prev-btn-mobile', { active: active }]"
          icon="el-icon-arrow-right"
          @mouseout.native="onMouseout"
          @mouseover.native="onMouseover"
        ></el-button>
      </el-button-group>
      <!--pc缩略图-->
      <div class="thumb-swiper pc swiper-container">
        <ul class="swiper-wrapper">
          <li class="swiper-slide" v-for="(item, index) in list" :key="index">
            <el-row class="content animate__animated animate__fadeInUp">
              <img
                :src="item.img"
                v-if="index == 0 || index == list.length - 1"
              />
              <img v-else v-lazy="item.img" />
              <el-row class="content-head">{{ item.title }}</el-row>
            </el-row>
          </li>
        </ul>
      </div>
      <!--移动屏显示的缩略图-->
      <div class="thumb-swiper-mobile mobile swiper-container">
        <ul class="swiper-wrapper">
          <li class="swiper-slide" v-for="(item, index) in list" :key="index">
            <el-row class="content animate__animated animate__fadeInUp">
              <img :src="item.img" />
              <el-row class="content-head">{{ item.title }}</el-row>
            </el-row>
          </li>
        </ul>
      </div>
    </el-row>
    <span class="more detail-btn"
      >详询方案<i class="el-icon-arrow-right"></i
    ></span>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
  Lazy,
  History,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
  Autoplay,
  Thumbs,
  Lazy,
  History,
]);
export default {
  name: "commponents-home-hdSolution",
  data() {
    return {
      active: false,
      list: [
        {
          title: "通讯",
          detail:
            "随着5G发展进入快车道，全客服数字化运营平台成为运营商们应对存量市场争夺的战略性选择。航动科技全流程数字化、可视化智能语音营销服务平台，将传统呼叫中心系统与AI技术平台深度融合，不仅为运营商提供集约化客服生产功能，并通过多省成功运营经验、平台建模和数据分析能力，持续为运营商挖掘用户价值和提供全场景精准支撑。",
          img: require("@/assets/images/case-img1.jpg"),
          url: "",
          linkList: [
            { title: "智能语音能力中台", url: "" },
            { title: "外呼机器人", url: "" },
            { title: "智能坐席助手", url: "" },
            { title: "智能质检", url: "" },
            { title: "全量语音分析", url: "" },
            // { title: "BPO", url: "" },
            { title: "运维支撑", url: "" },
            { title: "业务监控", url: "" },
          ],
        },
        {
          title: "金融",
          detail:
            "智慧金融在5G基建推动下正快速成为金融业新“风口”下重塑竞争力的关键。航动科技人机协同新客服模式及大数据精准营销平台，支持全媒体全渠道智能服务，可为银行、保险、证券等金融机构搭建企业级AI客服平台，通过服务",
          img: require("@/assets/images/case-img2.jpg"),
          url: "",
          linkList: [
            { title: "全媒体客服", url: "" },
            { title: "语音机器人", url: "" },
            { title: "智能外呼", url: "" },
            { title: "智能质检", url: "" },
            // { title: "BPO", url: "" },
            { title: "智能画像", url: "" },
            { title: "精准营销", url: "" },
          ],
        },
        {
          title: "出行",
          detail:
            "时代的不断发展，出行行业的市场对垒已经从用户争夺转向全产业链的竞争。航动科技通过智能客服系统和智能权益平台，为出行企业提供订单分流、全渠道在线服务支撑、权益匹配引流等服务，全面提升行业整体智能服务能力。",
          img: require("@/assets/images/case-img3.jpg"),
          url: "",
          linkList: [
            { title: "全媒体客服", url: "" },
            { title: "坐席管理系统", url: "" },
            { title: "智能坐席助手", url: "" },
            { title: "数据可视化平台", url: "" },
            { title: "工单系统", url: "" },
            { title: "智能权益平台", url: "" },
          ],
        },
        {
          title: "教育",
          detail:
            "后疫情时代下，在线教育的火爆将进一步加快教育行业竞争格局的变革。航动科技智能教育解决方案，基于互联网、人工智能、大数据技术，在深入洞悉行业需求和痛点的前提下，通过大数据赋能教育产品营销推广，在用户获存、在线咨询、客户服务、学员管理、数据整合等方面，帮助企业全面提升品牌市场竞争力。",
          img: require("@/assets/images/case-img4.jpg"),
          url: "",
          linkList: [
            { title: "智能外呼", url: "" },
            { title: "在线客服", url: "" },
            { title: "培训机器人", url: "" },
            { title: "智能坐席助手", url: "" },
            { title: "工单系统", url: "" },
            { title: "知识库", url: "" },
          ],
        },
        {
          title: "政企",
          detail:
            "随着5G发展进入快车道，全客服数字化运营平台成为运营商们应对存量市场争夺的战略性选择。航动科技全流程数字化、可视化智能语音营销服务平台，将传统呼叫中心系统与AI技术平台深度融合，不仅为运营商提供集约化客服生产功能，并通过多省成功运营经验、平台建模和数据分析能力，持续为运营商挖掘用户价值和提供全场景精准支撑。",
          img: require("@/assets/images/case-img5.jpg"),
          url: "",
          linkList: [
            { title: "智能语音能力中台", url: "" },
            { title: "外呼机器人", url: "" },
            { title: "智能坐席助手", url: "" },
            { title: "智能质检", url: "" },
            { title: "全量语音分析", url: "" },
            // { title: "BPO", url: "" },
            { title: "运维支撑", url: "" },
            { title: "业务监控", url: "" },
          ],
        },
        {
          title: "互联网",
          detail:
            "随着5G发展进入快车道，全客服数字化运营平台成为运营商们应对存量市场争夺的战略性选择。航动科技全流程数字化、可视化智能语音营销服务平台，将传统呼叫中心系统与AI技术平台深度融合，不仅为运营商提供集约化客服生产功能，并通过多省成功运营经验、平台建模和数据分析能力，持续为运营商挖掘用户价值和提供全场景精准支撑。",
          img: require("@/assets/images/case-img6.jpg"),
          url: "",
          linkList: [
            { title: "智能语音能力中台", url: "" },
            { title: "外呼机器人", url: "" },
            { title: "智能坐席助手", url: "" },
            { title: "智能质检", url: "" },
            { title: "全量语音分析", url: "" },
            // { title: "BPO", url: "" },
            { title: "运维支撑", url: "" },
            { title: "业务监控", url: "" },
          ],
        },
      ],
    };
  },
  mounted() {
    // this.pcShow()
    this.pcShow();
    this.mobileShow();
  },
  methods: {
    onMouseover() {
      this.active = true;
    },
    onMouseout() {
      this.active = false;
    },
    mobileShow() {
      new Swiper(".full-mobile-swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: false,
        loop: true,
        speed: 800,
        navigation: {
          nextEl: ".next-btn-mobile",
          prevEl: ".prev-btn-mobile",
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        thumbs: {
          swiper: {
            el: ".thumb-swiper-mobile",
            slidesPerView: 3,
            spaceBetween: 5,
            centeredSlides: false,
            speed: 800,
            observer: true,
            observeParents: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
              1600: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1366: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 5,
              },
              680: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            },
          },
        },
      });
    },
    pcShow() {
      new Swiper(".full-swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: false,
        loop: true,
        speed: 800,
        navigation: {
          nextEl: ".next-btn",
          prevEl: ".prev-btn",
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        thumbs: {
          swiper: {
            el: ".thumb-swiper",
            slidesPerView: 3,
            spaceBetween: 5,
            centeredSlides: false,
            speed: 800,
            observer: true,
            observeParents: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
              1600: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1366: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 5,
              },
              680: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            },
          },
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.commponents-home-hdSolution {
  padding-top: 95px;
  padding-bottom: 100px;
  .full-swiper {
    margin-top: 35px;
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    .content-wrapper {
      .content {
        left: 0;
        opacity: 1;
      }
    }
  }
  .content-wrapper {
    position: relative;
    & > img {
      width: 55.375%;
      // height: 500px;
      // width: 880px;
      float: right;
      border-radius: 3px;
      overflow: hidden;
      margin-right: 2px;
    }

    .content {
      //   width: 876px;
      // height: 376px;
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      width: 55%;
      text-align: left;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.03);
      background-color: #fff;
      padding: 55px 95px 75px 80px;
      transition: all 0.6s ease 0.3s;
      z-index: 2;
      opacity: 0;
      .title {
        font-size: 30px;
        color: #1f63d1;
        margin-bottom: 20px;
      }
      .detail {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
        height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .link-list {
        font-size: 16px;
        color: #1f63d1;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          margin-right: 5px;
          white-space: nowrap;
          position: relative;
          display: inline-block;
          cursor: pointer;
          //           .g
          //            {
          //     position: relative;
          //     top: -1px;
          //     margin: 0 5px;
          //       color: #1f63d1;
          // }

          & + li {
            margin: 0 5px;
            &::before {
              //1
              // content: "";
              // position: absolute;
              // top: 50%;
              // left: -4px;
              // transform: translateY(-50%);
              // width: 1px;
              // height: 16px;
              // background: #1f63d1;
              //2
              content: "|";
              position: relative;
              // top: 50%;
              left: -4px;
              top: -1px;
              transform: translateY(-50%);
              height: 16px;
              //3
            }
          }
        }
      }
    }
  }

  .thumb-wrapper {
    margin-top: 20px;
    position: relative;

    .opt-btns {
      &.opt-btns-mobile {
        z-index: -1;
      }
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      .el-button {
        width: 50px;
        height: 50px;
        font-size: 16px;
        border-radius: 50%;
        padding: 0;
        transition: all 0.5s ease;
        &:first-child {
          margin-right: 20px;
        }
        &.active {
          background-color: #1f63d2;
          color: #fff;
          border-color: #1f63d2;
        }
      }
    }
    .thumb-swiper,
    .thumb-swiper-mobile {
      float: right;
      width: 76.875%;
      .swiper-slide-thumb-active {
        .content {
          border-color: #1c5cc2;
        }
      }
      .content {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border-radius: 3px;
        border: solid 1px #fff;
        -webkit-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        img {
          width: 100%;
          display: block;
        }
        .content-head {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.7);
          font-size: 16px;
          color: #333333;
          height: 28px;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }
  .detail-btn {
    margin-top: 60px;
    span {
      border: 0;
    }
  }
}

@media screen and (max-width: 1680px) {
  .commponents-home-hdSolution {
    .content-wrapper {
      .content {
        padding: 35px 50px 50px 55px;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .commponents-home-hdSolution {
    .head-title {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 16px;
      }
    }
    .content-wrapper {
      .content {
        padding: 35px 50px 50px 45px;
        .title {
          font-size: 24px;
          margin-bottom: 10px;
        }
        .detail {
          font-size: 14px;
        }
        .link-list {
          font-size: 14px;
          margin-bottom: 30px;
        }
      }
    }
    .full-mobile-swiper {
      .content-wrapper {
        margin-bottom: 10px;
        .content {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          padding: 0;
          transition: all 0s ease 0s;
          opacity: 1;
          .title {
            margin-bottom: 10px;
          }
        }
      }
    }
    .thumb-wrapper {
      .opt-btns {
        .el-button {
          width: 40px;
          height: 40px;
          line-height: 38px;
          font-size: 16px;
        }
      }
      .thumb-swiper,
      .thumb-swiper-mobile {
        .content {
          .content-head {
            font-size: 14px;
          }
        }
      }
    }
    .detail-btn {
      margin-top: 45px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .commponents-home-hdSolution {
    .content-wrapper {
      .content {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .commponents-home-hdSolution {
    .content-wrapper {
      .content {
        .title {
          font-size: 16px;
        }
        .detail {
          font-size: 14px;
        }
      }
    }

    .thumb-wrapper {
      .opt-btns {
        .el-button {
          width: 30px;
          height: 30px;
          line-height: 28px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .commponents-home-hdSolution {
    .full-mobile-swiper {
      margin-top: 25px;
      .content-wrapper {
        position: relative;
        & > img {
          width: 100%;
          transition: all 0s ease 0s;
          transform: translate(0, 0);
          opacity: 1;
          margin-right: 0;
          position: relative;
          z-index: 3;
          margin-top: 0;
          margin-bottom: 25px;
        }
        .content {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          padding: 0;
          transition: all 0s ease 0s;
          opacity: 1;
          .title {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .detail {
            line-height: 20px;
            height: 40px;
            font-size: 12px;
          }
          .link-list {
            margin-bottom: 15px;
            font-size: 12px;
            li {
              & + li {
                &::before {
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
    .thumb-wrapper {
      .opt-btns {
        &.opt-btns-mobile {
          z-index: 999;
        }
        .el-button {
          width: 20px;
          height: 20px;
          line-height: 18px;
        }
      }
      .thumb-swiper,
      .thumb-swiper-mobile {
        .content {
          .content-head {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <!-- 行业解决方案 -->
  <div class="commponents-home-hdFeature">
    <div class="head-title  animate__animated animate__fadeInUp">
      <h2>航动优势</h2>
      <h3>全触点数字化智能服务品牌</h3>
    </div>
    <div class="content-wrapper">
      <el-image
        class="animate__animated animate__fadeInUp"
        :src="pic"
      />
      <ul>
        <li
          class="list-item animate__animated animate__fadeInUp"
          v-for="(item,index) in list"
          :key="index"
          @click="onClick(index)"
        >
          <i><span></span></i>
          <div class="content-box">
            <div class="content">
              <strong v-if="item.strong">{{item.strong}}
                <small v-if="item.small">{{item.small}}</small>
                <sup v-if="item.sup">{{item.sup}}</sup>
              </strong>
              <p>{{item.title}}</p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="mobile-list">
        <template v-for="(item,index) in list">
          <li
            class="animate__animated animate__fadeIn"
            v-show="active==index"
            :key="index"
          >
            <div class="content-box">
              <div class="content">
                <strong v-if="item.strong">{{item.strong}}
                  <small v-if="item.small">{{item.small}}</small>
                  <sup v-if="item.sup">{{item.sup}}</sup>
                </strong>
                <p>{{item.title}}</p>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "commponents-home-hdFeature",
  data() {
    return {
      active: undefined,
      pic: require("@/assets/images/idx-youshi-img.png"),
      list: [
        {
          strong: "20",
          small: "万",
          sup: "+",
          title: "在线坐席，全客户精准触达",
        },
        {
          strong: "6",
          small: "大",
          sup: "",
          title: "中心&基地，产业落地能力强",
        },
        {
          strong: "18",
          small: "省",
          sup: "",
          title: "运营经验，全场景数字赋能",
        },
        {
          strong: "10",
          small: "年",
          sup: "",
          title: "行业经验，全流程智能交互",
        },
        {
          strong: "3",
          small: "重",
          sup: "",
          title: "加密防护，全方位隐私保障",
        },
      ],
    };
  },
  methods: {
    onClick(index) {
      this.active = index;
    },
  },
};
</script>
<style lang="less" scoped>
.commponents-home-hdFeature {
  background: url("../../assets/images/idx-youshibg.jpg") no-repeat center
    center/cover;
  margin: auto;
  padding: 60px 0 45px 0;
  box-sizing: border-box;
  .head-title {
    h2,
    h3 {
      color: #fff;
    }
  }
  .content-wrapper {
    position: relative;
    width: 71.477%;
    margin: auto;
    margin-top: -50px;
    .list-item {
      color: #fff;
      width: 45px;
      height: 45px;
      position: absolute;
      &:nth-child(1) {
        top: 29.046%;
        left: 20.2725%;
      }
      &:nth-child(2) {
        top: 21.053%;
        left: 66.277%;
      }
      &:nth-child(3) {
        top: 31.532%;
        left: 85.555%;
      }
      &:nth-child(4) {
        top: 66.666%;
        left: 77.944%;
      }
      &:nth-child(5) {
        top: 72.756%;
        left: 59.538%;
      }
      .content-box {
         width: 425px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        overflow: hidden;
        border-radius: 60px;
        transition: all 0.5s ease;
        .content {
         width: 425px;
          height: 100%;
          background-image: linear-gradient(
            0deg,
            rgb(27, 185, 238) 0%,
            rgb(25, 142, 234) 33%,
            rgb(21, 99, 228) 100%
          );
         box-shadow: 0px 0px 18px 0px rgb(0 0 0 / 20%);
          flex-shrink: 0;
          visibility: hidden;
          opacity: 0;
          transition: all 0.5s ease;
          display: flex;
          align-items: center;
          padding: 0 .5rem;
          color: #fff;
          font-size: 16px;
        }
      }
      strong {
        font-size: 30px;
        margin-right: 5px;
        flex-shrink: 0;
        small {
          position: relative;
          top: -3px;
          margin-left: -.1rem;
          font-size: 75%;
        }
      }
      sup {
        top: -0.3em;
        font-size: 18px;
      }
      i {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        display: block;
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease 0s;
        span {
          content: "";
          background-color: #ffe562;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: wave 2s linear 0s infinite;
          z-index: -1;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          z-index: 1;
          border-radius: 100%;
        }
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffe461;
        border-radius: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease 0s;
      }
    }
    .mobile-list {
      display: none;
      li {
        color: #fff;
        padding: 10px 25px;
        font-size: 14px;
        .content {
          transition: all 0.5s ease;
          display: flex;
          align-items: center;
          strong {
            font-size: 18px;
            flex-shrink: 0;
            small {
              position: relative;
              top: -1px;
              // font-size: 85%;
            }
          }
          sup {
            top: -0.3em;
          }
        }
      }
    }
  }
}
@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  3% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  40% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  80% {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
}


@media screen and (max-width: 1560px) {
  .commponents-home-hdFeature {

    .content-wrapper {
      .list-item {
        .content-box {
          width: 345px!important;
          .content{
font-size: 14px!important;
          }
          
          strong{
            font-size: 24px;
            small{
              // font-size: 22px;

            }
          }
        }
      
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .commponents-home-hdFeature {
    padding-top: 55px;
    .content-wrapper {
      .list-item {
        width: 50px;
        height: 50px;
        .content-box {
          // padding:0 25px;
          width: 290px!important;
          .content{
            // font-size: .175rem !important;
          }
        }
      }
    }
  }
}
/* 放置全部hover效果 */
@media screen and (min-width: 1024px) {
  .commponents-home-hdFeature {
    padding-top: 55px;
    .content-wrapper {
      .list-item {
        &:hover {
          .content-box {
          width: 425px;
            .content {
              visibility: visible;
              opacity: 1;
            }
          }
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .idxYs-li:hover .div {
    width: 425px;
  }
  .idxYs-li:hover .div > div {
    visibility: visible;
    opacity: 1;
  }
  .idxYs-li:hover:after {
    visibility: visible;
    opacity: 1;
  }
}


@media screen and (max-width: 992px) and (orientation: portrait) {
  .commponents-home-hdFeature {
    padding-top: 35px;
    .content-wrapper {
      width: 100%;
      .mobile-list {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .commponents-home-hdFeature {
    .content-wrapper {
      width: 93%;
      margin-left: 0;
      margin-top: 0;
      .list-item {
        width: 20px;
        height: 20px;
        i {
          width: 6px;
          height: 6px;
          span {
            animation-duration: 1.2s;
          }
        }
      }
      .mobile-list {
        .list-item {
          width: 100%;
        }
      }
    }
  }
  @keyframes wave {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    3% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    50% {
      width: 10px;
      height: 10px;
      opacity: 1;
    }
    100% {
      width: 20px;
      height: 20px;
      opacity: 0;
    }
  }
}
</style>